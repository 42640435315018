import Vue from 'vue'
import VueRouter from 'vue-router'

// Routes
import { canNavigate } from '@/libs/acl/routeProtection'
import { isUserLoggedIn, getUserData, getHomeRouteForLoggedInUser } from '@/auth/utils'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    {
      path: '/',
      name: 'login',
      component: () => import('@/views/Login.vue'),
      meta: {
        layout: 'full',
        action: 'read',
        resource: 'Auth',
        redirectIfLoggedIn: true,
      },
    },
    {
      path: '/stage2',
      name: 'Stage2',
      component: () => import('@/views/ecommerce/Ecommerce.vue'),
      meta: {
        pageTitle: 'Stage2 Analytics',
        isAuthRequired: true,
        breadcrumb: [
          {
            text: 'SBI YFI Stage2 Analytics',
            active: true,
            resource: 'Auth',
          },
        ],
      },
    },
    {
      path: '/stage1',
      name: 'Stage1',
      component: () => import('@/views/analytics/Analytics.vue'),
      meta: {
        pageTitle: 'Stage1 Analytics',
        isAuthRequired: true,
        breadcrumb: [
          {
            text: 'SBI YFI Stage1 Analytics',
            active: true,
            resource: 'Auth',
          },
        ],
      },
    },
    // {
    //   path: '/home',
    //   name: 'home',
    //   component: () => import('@/views/Home.vue'),
    //   meta: {
    //     pageTitle: 'Home',
    //     isAuthRequired: true,
    //     breadcrumb: [
    //       {
    //         text: 'Home',
    //         active: true,
    //         resource: 'Auth',
    //       },
    //     ],
    //   },
    // },
    // {
    //   path: '/second-page',
    //   name: 'second-page',
    //   component: () => import('@/views/SecondPage.vue'),
    //   meta: {
    //     pageTitle: 'Second Page',
    //     breadcrumb: [
    //       {
    //         text: 'Second Page',
    //         active: true,
    //       },
    //     ],
    //   },
    // },
    {
      path: '/pages/not-authorized',
      name: 'not-authorized',
      // ! Update import path
      component: () => import('@/views/NotAuthorized.vue'),
      meta: {
        layout: 'full',
        action: 'read',
        resource: 'Auth',
      },
    },
    {
      path: '/error-404',
      name: 'error-404',
      component: () => import('@/views/error/Error404.vue'),
      meta: {
        layout: 'full',
        action: 'read',
        resource: 'Auth',
      },
    },
    {
      path: '*',
      redirect: 'error-404',
    },
  ],
})

// Demo auth
router.beforeEach((to, _, next) => {
  const isLoggedIn = isUserLoggedIn()

  if (!canNavigate(to)) {
    // Redirect to login if not logged in
    if (!isLoggedIn) return next({ name: 'login' })

    // If logged in => not authorized
    return next({ name: 'not-authorized' })
  }

  // Redirect if logged in
  if (to.meta.redirectIfLoggedIn && isLoggedIn) {
    const userData = getUserData()
    next(getHomeRouteForLoggedInUser(userData ? userData.role : null))
    // next(getHomeRouteForLoggedInUser())
  }

  return next()
})

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
})

export default router
